<template>
  <div class="item-image">
    <app-proxy-pdf
      v-if="isPdf"
      ref="renderer"
      :src="imgSrc"
      class="file-image__pdf" />
    <img
      v-else
      v-lazy="{
        src: imgSrc,
        loading: defaultProductImage,
        error: defaultProductImage,
      }"
      :style="heightOfImage"
      style="object-fit: cover;"
      @load="$redrawVueMasonry()">
  </div>
</template>
<script>
import {
  mapActions, mapGetters,
} from 'vuex';
import {
  parseStorageKey, waitTimeout,
} from '@/utils';
import { Storage } from 'aws-amplify';
import API from '@/services/graphql';
import fileAttempts from '@/mixins/fileAttempts';
import defaultProductImage from '@/assets/icons/placeholder.svg';
import AppProxyPdf from '@/components/App/AppProxyPdf/index.vue';
import { watchBy } from '@/utils/vueHelpers';
import { ORIGINAL } from '@/constants/thumbnails';
export default {
  name: 'ElementImage',
  components: {
    AppProxyPdf,
  },
  mixins: [fileAttempts],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    heightOfImage: {
      type: String,
      default: () => 'height: auto;',
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgSrc: '',
      values: [],
      defaultProductImage,
    };
  },
  computed: {
    ...mapGetters('ManageFiles', ['getFileByKey']),
    ...mapGetters('FeatureFlags', ['useIncreaseCaching', 'useNewPdfLibrary']),
    ...mapGetters('Images', ['getImageOrThumbnailKey', 'getImageFetchPromise']),
    isPdf() {
      return this.itemUnit?.type?.includes('application/pdf');
    },
    useNewPdfComponent() {
      return this.useNewPdfLibrary && this.isPdf;
    },
    itemUnit() {
      return this.values[0];
    },
    cachedImageKey() {
      if (!this.itemUnit) return '';
      return this.getImageOrThumbnailKey(this.itemUnit.id, ORIGINAL);
    },
  },
  watch: {
    items: {
      async handler(val) {
        if (val[0]?.key !== this.itemUnit?.key) {
          await this.getUrlForValues();
        }
        this.fetchImage();
      },
    },
    async cachedImageKey(val) {
      if (val) {
        this.imgSrc = await this.getImageFromCache(val);
      }
    },
  },
  async created() {
    await this.getUrlForValues();
  },
  beforeDestroy() {
    clearTimeout(this.syncTime);
  },
  mounted() {
    watchBy('isShown', async (val, unsubscribe) => {
      await waitTimeout(100);
      const pdfComponent = this.$refs?.renderer?.componentInstance;
      if (pdfComponent?.isRendered || !this.useNewPdfComponent) {
        unsubscribe();
        return;
      }
      if (!pdfComponent || !val) return;
      if (val && !pdfComponent?.isRendered) {
        await pdfComponent.renderAsThumbnail();
        unsubscribe();
      }
    }, this);
  },
  methods: {
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    ...mapActions('Images', ['fetchDataUrl', 'getImageFromCache']),

    async getUrlForValues(valueIds = []) {
      let valueWithImagesUrls = null;
      valueWithImagesUrls = await Promise.all(this.items.map(async (v) => {
        if (!v) return;
        let { key: initKey, url: initUrl, id } = v ?? {
        };
        if (!initKey && !initUrl) {
          v = await this.getAttachmentInfo(id ?? v);
        }
        let { key, url } = v ?? {
        };
        if (key && !url || valueIds.includes(id)) {
          const { key: parsedKey, identityId } = parseStorageKey(key);
          if (this.useIncreaseCaching) {
            await this.parseFile({
              key: parsedKey,
              config: {
                level: 'protected',
                identityId,
              },
            });
            v.url = this.getFileByKey(parsedKey);
          } else {
            v.url = await Storage.get(
              parsedKey, {
                level: 'protected',
                identityId,
              }
            );
          }
        }
        return v;
      }));
      this.values = valueWithImagesUrls;
    },
    async getAttachmentInfo(id) {
      try {
        const { data } = await API.getAttachmentInfo({
          id,
        });
        return data.response;
      } catch (err) {
        console.log('err getAttachmentInfo');
      }
    },

    async fetchImage() {
      if (!this.itemUnit || this.isPdf) return;
      this.fetchDataUrl({
        key: this.itemUnit.id,
        size: ORIGINAL,
        url: this.itemUnit.url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-image {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: -60%;
    display: block;
    content: '';
    width: 0%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(-25deg);
  }

  &:hover::before {
    width: 50%;
    animation: shine 1s;
  }

  img {
    width: 100%;
    border-radius: 15px;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
</style>
